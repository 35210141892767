<app-logged class="store-list">
  <header class="store-list__header">
    <h2>MOVIMENTAÇÕES DE JCOINS</h2>
    <div class="store-list__header__actions">
      <button
        *ngIf="!architect"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/clients"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
      <button
        *ngIf="architect"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/clients-architect/architect"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>
  <app-form-filter
    [cpf]="cpf"
    actions="form"
    type="clientMovement"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter>
  <div class="table-responsive">
    <mat-card class="card-category">
      <div><b>NOME: </b>{{ fullName }}</div>
      <div><b>CPF: </b>{{ cpf | mask: '000.000.000-00' }}</div>
      <div><b>E-MAIL: </b>{{ email }}</div>
      <div><b>Saldo Atual J-Coins: </b>{{ jcoinBalance }}</div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="transactionId">
        <th mat-header-cell *matHeaderCellDef>Número</th>
        <td mat-cell *matCellDef="let element" style="width: 10px">{{ element.transactionId }}</td>
      </ng-container>
      <ng-container matColumnDef="transactionDate">
        <th mat-header-cell *matHeaderCellDef style="padding-left: 5px">Data</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ 
            (element.creditDate && element.creditDatePreRegistration !== 'null' && element.creditDatePreRegistration !== 'undefined') ? 
              element.creditDatePreRegistration : 
              element.transactionDate | date: 'dd/MM/yyyy' }}
        </td>        
      </ng-container>      
      <ng-container matColumnDef="partnerName">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.partnerName }}"
          class="cellText"
        >
          {{ element.partnerName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionDescription">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.transactionDescription }}"
          class="cellText"
        >
          {{ element.transactionDescription }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionValue">
        <th mat-header-cell *matHeaderCellDef>Valor Total</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ convertCentsToReal(element) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="outJcoinQt">
        <th mat-header-cell *matHeaderCellDef>Quantidade</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.outJcoinQt }}
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef class="groupHeaderLeft">Status</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ convertState(element) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expiration">
        <th mat-header-cell *matHeaderCellDef>Data de Expiração</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.expiration | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="flow" class="cellText">
        <th mat-header-cell *matHeaderCellDef>I / O</th>
        <td mat-cell *matCellDef="let element">
          {{ convertFlow(element.flow) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="jcoinQtde" class="cellText">
        <th mat-header-cell *matHeaderCellDef>Quantidade</th>
        <td mat-cell *matCellDef="let element">
          {{ element.jcoinQtde }}
        </td>
      </ng-container>
      <ng-container matColumnDef="jcoinValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.jcoinValue | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="accumulationType" class="cellText">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.accumulationType }}
        </td>
      </ng-container>
      <ng-container

      matColumnDef="chargeBack" class="cellText">
        <th mat-header-cell *matHeaderCellDef>Estorno</th>
        <td mat-cell *matCellDef="let element" class="actionCell">
          <div
            *ngIf="
              userRole != 'CUSTOMER_SERVICE' &&
                convertFlow(element.flow) === 'ENTRADA' &&
                element.accumulationType === 'MANUAL' &&
                element.llId !== null &&
                (jcoinBalance >= element.jcoinQtde || element.state === 'CANCELED');
              else elseChargeBack
            "
          >
            <button
              *ngIf="element.state === 'COMPUTED'"
              mat-icon-button
              matTooltip="Cancelar"
              (click)="openDialogChargeBack(element)"
              matTooltipPosition="left"
            >
              <mat-icon class="canceltransaction">cancel</mat-icon>
            </button>
            <span
              *ngIf="element.state === 'CANCELED'"
              matTooltip="{{ element.cancelDate | date: 'dd/MM/yyyy HH:mm:ss' }}"
              matTooltipPosition="left"
            >
              {{ element.cancelDate | date: 'dd/MM/yyyy HH:mm' }}
            </span>
          </div>

          <ng-template #elseChargeBack>
            <span>N/A</span>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="header-row-transactions-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="groupHeaderLeft">
          Transações
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-jcoins-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="7" class="groupHeaderLeft">
          J-Coins
        </th>
      </ng-container>
      <tr
        mat-header-row
        dir="rtl"
        *matHeaderRowDef="['header-row-transactions-group', 'header-row-jcoins-group']"
      ></tr>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter
      [cpf]="cpf"
      type="clientMovement"
      actions="pagination"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>
  </div>
</app-logged>
