import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import { Location } from '@angular/common'
import numeral from 'numeral'

import { AuthService } from '../../../../services/auth.service'
import { StoreService } from '../../../../services/store.service'
import { ModalFormStatementByCustomerComponent } from '../../../../components/modal-form-statementByCustomer/modal-form-statementByCustomer.component'
import { ModalFormChargeBackComponent } from 'src/app/components/modal-form-charge-back/modal-form-charge-back.component'
import { JcoinsMovementState } from 'src/app/models/jcoinsMovementState'

@Component({
  selector: 'app-client-movement-jcoins',
  templateUrl: './client-movement-jcoins.component.html',
  styleUrls: ['./client-movement-jcoins.component.scss'],
})
export class ClientMovementJcoinsComponent implements OnInit {
  customerId: string
  cpf: string
  fullName: string
  email: string
  jcoinBalance: number

  public userRole: any

  public headersTable: string[] = [
    'transactionId',
    'transactionDate',
    'partnerName',
    'transactionDescription',
    'transactionValue',
    'state',
    'expiration',
    'flow',
    'jcoinQtde',
    'jcoinValue',
    'accumulationType',
    'chargeBack',
  ]

  dataSource: any
  fieldsForm: any
  public pageTotal: number
  public params = { formGroupValue: [] }
  public architect: any

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    private readonly route: ActivatedRoute,
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private readonly location: Location,
    public storeService: StoreService,
    public dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.userRole = localStorage.getItem('userRole')
    this.architect = this.route.snapshot.paramMap.get('architect')
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.customerId = this.route.snapshot.paramMap.get('customerID')

    this.init().catch(console.log)
  }

  async init () {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'date',
        label: 'Data Início',
        formControlName: 'baginDateMovementJcoins',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Final',
        formControlName: 'endDateMovementJcoins',
      },
    ]

    await this.storeService
      .listUsersCustomers(
        `?filters={"filters":[{"fields":["customers.id"], "op": "=", "value":"${this.customerId}"}]}`,
      )
      .toPromise()
      .then(
        async response => {
          const customer = response.items.find(() => true)
          this.cpf = customer.cpf
          this.fullName = `${customer.firstName} ${customer.lastName}`
          this.email = customer.emailAddress
          this.jcoinBalance = customer.jcoinBalance
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    await this.authService
      .listMovementJcoins(this.cpf, `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
      .then(
        async response => {
          this.pageTotal = response.total
          this.dataSource = new MatTableDataSource(response.results)
          console.log(this.dataSource)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  goBack () {
    this.location.back()
  }

  openDialogChargeBack (element) {
    this.dialog
      .open(ModalFormChargeBackComponent, {
        width: '500px',
        data: {
          partnerName: element.partnerName,
          transactionDate: element.creditDatePreRegistration ? element.creditDatePreRegistration :  element.transactionDate,
          jcoinValue: element.jcoinValue,
          walletId: element.walletExecutionId,
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          this.snackBar.open('Estorno realizado com sucesso!')
          this.init().catch(console.log)
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  convertState (jcoins) {
    return JcoinsMovementState[jcoins.state]
  }

  convertFlow (flow) {
    if (flow === 'IN') return 'ENTRADA'
    return 'SAÍDA'
  }

  convertCentsToReal (jcoin) {
    return numeral(Number(jcoin.transactionValue)).divide(100).value()
  }

  report (cpf, fullName) {
    this.dialog.open(ModalFormStatementByCustomerComponent, {
      width: '550px',
      data: {
        cpf,
        fullName,
      },
    })
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
