<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div style="overflow-y: hidden; max-height: calc(100vh - 15rem)">
    <div style="overflow-y: auto; height: 100%">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div mat-dialog-content class="content">
          <mat-card>
            <div class="row">
              <h5>Relatório de Estoque Inicial de Jcoins</h5>
              <div class="col-12 col-sm-12">
                <mat-form-field>
                  <mat-select formControlName="month">
                    <mat-option [value]="1">Janeiro</mat-option>
                    <mat-option [value]="2">Fevereiro</mat-option>
                    <mat-option [value]="3">Março</mat-option>
                    <mat-option [value]="4">Abril</mat-option>
                    <mat-option [value]="5">Maio</mat-option>
                    <mat-option [value]="6">Junho</mat-option>
                    <mat-option [value]="7">Julho</mat-option>
                    <mat-option [value]="8">Agosto</mat-option>
                    <mat-option [value]="9">Setembro</mat-option>
                    <mat-option [value]="10">Outubro</mat-option>
                    <mat-option [value]="11">Novembro</mat-option>
                    <mat-option [value]="12">Dezembro</mat-option>
                  </mat-select>
                  <mat-error *ngIf="formGroup.controls.month?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.month) }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput formControlName="year" placeholder="Ano" />
                  <mat-error *ngIf="formGroup.controls.year?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.year) }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button mat-button class="btn-submit-dark">Exportar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
