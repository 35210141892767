import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { Store } from 'src/app/models/store'
import { MatDialog, MatSnackBar } from '@angular/material'
import { DatePipe } from '@angular/common'
import { Observable } from 'rxjs'
import { User } from 'src/app/models/user'
import { ConfigService } from 'src/app/services/config.service'
import numeral from 'numeral'
import { CampaignService } from 'src/app/services/campaign.service'
import { ModalChooseCampaignTypeComponent } from '../../../../components/modal-choose-campaign-type/modal-choose-campaign-type.component'
import { ModalPartnerEditionMacroBindingInformation } from '../../../../components/modal-partner-edition-macro-binding-information/modal-partner-edition-macro-binding-information.component'
import { enumInternationalCurrency } from 'src/app/models/enumInternationalCurrency'
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service'
import { ModalStoreBankAccountChangedComponent } from 'src/app/components/modal-store-bank-account-changed/modal-store-bank-account-changed.component'

@Component({
  selector: 'app-store-form',
  templateUrl: './store-int-form.component.html',
  styleUrls: ['./store-int-form.component.scss'],
})
export class StoreIntFormComponent implements OnInit {
  public formGroup: FormGroup
  public items: FormArray
  public states: Object[] = []
  public storeID = ''
  public store: Store
  public cnpjSelected = null
  public title = 'app'
  public elementType = 'string'
  public valueQrCode = ''
  public segmentos: any[] = []
  public bankCodes: any[] = []
  public userInfo: User
  public configs: any = []

  public params = { storeFormGroupValue: [] }
  public campaigns: any[] = []

  public isMembershipActive = false
  public isPayActive = false
  public userRole: any
  public isCardPayment: boolean = false
  public isJcoinsPayment: boolean = true
  public isJcoinsPriorityPayment: boolean
  public typePayment: string
  public macroGroups: any[] = []
  public isSubStore: boolean
  public groups: any[] = []
  public subStoreNone: any[] = []
  public subStoreNameInvalid = false
  public macroGroupIdInvalid = false
  public messageMacroGroupError = false
  public messageSubError = false
  public subAndMacroInvalid = false
  public isActiveRoadpass: boolean
  public isZoopEnabled: boolean
  public idCampaign: string
  public descriptionCampaign: string
  public isUpdate = false
  public campaignId: string
  public defaultCampaignId: string
  public enumInternationalCurrency: string[] = Object.values(enumInternationalCurrency)
  public isInternational: boolean = false

  public segmentosOptions: Observable<any[]>
  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private readonly datePipe: DatePipe,
    public configService: ConfigService,
    public dialog: MatDialog,
    public campaignService: CampaignService,
    private liquidationTrasnferService: LiquidationTransferService,
    private cdr: ChangeDetectorRef
  ) {
    this.initForm()
  }

  ngOnInit () {
    this.loadCampaigns()
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
    })
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.userRole = localStorage.getItem('userRole')
  }

  async initForm () {
    this.cnpjSelected = this.route.snapshot.paramMap.get('cnpj');
    this.storeID = this.route.snapshot.paramMap.get('storeID');
    this.isUpdate = !!this.storeID;
    this.valueQrCode = this.storeID;
  
    this.formGroup = this.formBuilder.group({
      nomeFantasia: [null, [Validators.required]],
      razaoSocial: [null, [Validators.required]],
      complement: ['', []],
      document: [
        this.cnpjSelected,
        [Validators.required, Validators.minLength(5)],
      ],
      phoneNumber: [null, [Validators.required]],
      emailAddress: [null, [Validators.required]],
      mcc: [null, []],
      mccDescription: ['PARCEIRO INTERNATIONAL', []],
      mccCategory: ['PARCEIRO INTERNATIONAL', []],
      mccCode: ['000', []],
      openingDate: [null, [Validators.required]],
      liquidationDays: [30, [Validators.required]],
      isMembershipActive: [true],
      isPayActive: [false],
      manualPaymentEnabled: [false],
      futurePaymentEnabled: [false],
      isConciergePaymentStore: [false],
      taxCashback: [null, []],
      internationalPartnerJcoinTransactionTax: [null, [Validators.required]],
      taxAdministrative: [null, []],
      taxRewardsTransaction: [null, [Validators.required]],
      taxCreditCardTransaction: [null, []],
      pointsConversionFactorIn: [null, []],
      pointsConversionFactorOut: [null, [Validators.required]],
      pointsExpiration: [null, []],
      maximumInstallmentsPerTransaction: [null, []],
      minimumInstallmentValue: [null, []],
      minimumTransactionValue: [null, []],
      street: [null, [Validators.required]],
      number: [null, [Validators.required]],
      neighborhood: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      zipCode: [null, [Validators.required, Validators.minLength(2), Validators.pattern('^[0-9]*$')]],
      typePayment: ['jcoinsPayment', []],
      isSubStore: [null, []],
      macroGroup: [null, []],
      subStores: this.formBuilder.array([this.createMacroGroupFormGroup()]),
      bankCode: [null, []],
      bankName: [null, [Validators.required]],
      agency: [null, []],
      accountNumber: [null, [Validators.required]],
      accountDigit: [null, []],
      accountType: [null, []],
      isInternational: [true, []],
      currency: [enumInternationalCurrency.USD, [Validators.required]],
      zoopRevenue: [1000000, []],
    });

    if (this.cnpjSelected > 0) {
      this.formGroup.get('document').enable()
      this.loadInfosConfigs()
    } else {
      this.formGroup.get('document').disable()
    }

    if (this.cnpjSelected > 0) {
      await this.getStoreByDocument()
    } else {
      await this.loadInfosStore()
    }

    this.listMacroGroups()
  }

  listMacroGroups () {
    this.storeService.listMacroGroups().then(
      async response => {
        this.macroGroups = response.results
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  changeIsSubStore (e) {
    this.isSubStore = e
    const subStores = this.formGroup.get('subStores') as FormArray
    if (!e) {
      for (const s of subStores.value) {
        s.subStoreName = null
        s.subStoreId = null
      }
      for (const s of subStores.controls) {
        s.value.subStoreName = null
        s.value.subStoreId = null
        this.subStoreNone.push(s.value)
      }
      this.subStoreNameInvalid = false
      this.macroGroupIdInvalid = false
      this.subAndMacroInvalid = false
      this.messageSubError = false
    }
  }

  keyPress (e) {
    const subStores = this.formGroup.get('subStores') as FormArray
    this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName
  }

  validStoreName (e) {
    const subStores = this.formGroup.get('subStores') as FormArray
    this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName
  }

  changeMacroGroup (e) {
    const subStores = this.formGroup.get('subStores') as FormArray
    for (const s of subStores.value) {
      s.id = !s.id ? null : s.id
      s.macroGroupId = !s.macroGroupId ? null : s.macroGroupId
      s.subStoreId = !s.subStoreId ? null : s.subStoreId
      s.subStoreName = !s.subStoreName ? null : s.subStoreName
    }
    this.macroGroupIdInvalid = !subStores.controls[0].value.macroGroupId
  }

  addMacroGroupFormGroup (event) {
    event.preventDefault()
    const subStores = this.formGroup.get('subStores') as FormArray
    this.subAndMacroInvalid = false
    subStores.push(this.createMacroGroupFormGroup())
  }

  removeOrClearMacroGroup (event, i: number) {
    event.preventDefault()
    const subStores = this.formGroup.get('subStores') as FormArray
    if (i >= 1) {
      subStores.controls[i].value.subStoreName = null
      subStores.controls[i].value.macroGroupId = null
      subStores.controls[i].value.subStoreId = null
      this.subStoreNone.push(subStores.controls[i].value)
      subStores.removeAt(i)
    } else {
      subStores.controls[i].value.subStoreName = null
      subStores.controls[i].value.macroGroupId = null
      subStores.controls[i].value.subStoreId = null
      this.subStoreNone.push(subStores.controls[i].value)
      subStores.reset()
    }
  }

  createMacroGroupFormGroup (): FormGroup {
    return new FormGroup({
      subStoreName: new FormControl(undefined),
      subStoreId: new FormControl(undefined),
      macroGroupId: new FormControl(undefined),
      id: new FormControl(undefined),
    })
  }

  async getStoreByDocument () {
    this.storeService.getStoreByDocument(this.cnpjSelected).toPromise().then(
      async response => {
        this.store = response
        this.isUpdate = !!response
        this.setInfosStore()
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  async loadInfosStore () {
    this.storeService.getStore(this.storeID).subscribe(
      async response => {
        this.store = response
        this.setInfosStore()
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  loadInfosConfigs () {
    this.configService.view().subscribe(
      async response => {
        this.configs = response
        this.formGroup.patchValue({
          pointsExpiration: this.configs.results.jcoinsExpirationInDays,
        })
      },
      error => {
        alert(error.error.message)
      },
    )
  }

  setInfosStore() {
    console.log('store', this.store)
    if (typeof this.store !== 'object' || !this.store) {
      console.error('Store is not an object or is undefined:', this.store);
      return;
    }
  
    this.isSubStore = this.store.isSubStore;
    this.isMembershipActive = this.store.isMembershipActive;
    this.isPayActive = this.store.isPayActive ? this.store.isPayActive : false;
    this.isActiveRoadpass = this.store.isActiveRoadpass;
    this.isZoopEnabled = this.store.isZoopEnabled;
  
    this.idCampaign = this.store.idCampaign;
    this.descriptionCampaign = this.store.descriptionCampaign;
    this.isInternational = this.store.isInternational;
    this.typePayment = 'jcoinsPayment';
    const subStores = this.formGroup.get('subStores') as FormArray;
    this.groups = [];
  
    if (Array.isArray(this.store.subStoreAndMacroGroup)) {
      for (const sm of this.store.subStoreAndMacroGroup) {
        subStores.push(this.createMacroGroupFormGroup());
        const mg = sm.macroGroup.map((mg) => mg.id);
        const sb = sm.subStore.map((sb) => sb.subStoreName);
        const sbId = sm.subStore.map((sb) => sb.id);
  
        this.groups.push({
          subStoreName: sb[0],
          subStoreId: sbId[0],
          macroGroupId: mg[0],
          id: sm.id,
        });
      }
    }
  
    if (!this.store.mccCode) {
      const filterMcc = this.segmentos.filter(
        (s) => s.description === this.store.mccDescription
      );
      if (filterMcc.length) {
        this.store.mccCode = filterMcc[0].code;
      }
    }
  
    this.formGroup.patchValue({
      nomeFantasia: this.store.nomeFantasia,
      razaoSocial: this.store.razaoSocial,
      complement: this.store.complement != null ? this.store.complement : '',
      document: this.store.document,
      emailAddress: this.store.emailAddress,
      phoneNumber: this.store.phoneNumber,
      openingDate: this.store.openingDate.substring(0, 10),
      liquidationDays: this.store.liquidationDays,
      mcc: this.store.mccCategory + ' - ' + this.store.mccDescription,
      mccCategory: this.store.mccCategory,
      mccDescription: this.store.mccDescription,
      mccCode: this.store.mccCode,
      isMembershipActive: this.store.isMembershipActive,
      isPayActive: this.store.isPayActive,
      manualPaymentEnabled: this.store.manualPaymentEnabled,
      futurePaymentEnabled: this.store.futurePaymentEnabled,
      isConciergePaymentStore: this.store.isConciergePaymentStore || false,
      maximumInstallmentsPerTransaction: this.store.maximumInstallmentsPerTransaction,
      minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
      taxCreditCardTransaction: numeral(this.store.taxCreditCardTransaction).multiply(100).value(),
      pointsExpiration: this.store.pointsExpiration,
      minimumTransactionValue: this.store.minimumTransactionValue / 100,
      pointsConversionFactorIn: this.store.pointsConversionFactorIn / 100,
      pointsConversionFactorOut: this.store.pointsConversionFactorOut / 100,
      taxCashback: numeral(this.store.taxCashback).multiply(100).value(),
      internationalPartnerJcoinTransactionTax: numeral(this.store.internationalPartnerJcoinTransactionTax).multiply(100).value(),
      taxAdministrative: numeral(this.store.taxAdministrative).multiply(100).value(),
      taxRewardsTransaction: numeral(this.store.taxRewardsTransaction).multiply(100).value(),
      street: this.store.street,
      number: this.store.number,
      neighborhood: this.store.neighborhood,
      city: this.store.city,
      state: this.store.state,
      zipCode: this.store.zipCode,
      typePayment: this.typePayment,
      isSubStore: this.store.isSubStore,
      subStores: this.groups,
    
      // Verificação ajustada para bankAccounts e bank
      bankName: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
      ? this.store.bankAccounts[0].bankName
      : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
      ? this.store.bankAccounts.bankName
      : this.store.bank
      ? this.store.bank.bankName
      : null,
    
      agency: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
        ? this.store.bankAccounts[0].agency
        : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
        ? this.store.bankAccounts.agency
        : this.store.bank
        ? this.store.bank.agency
        : null,
    
      accountNumber: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
        ? this.store.bankAccounts[0].accountNumber
        : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
        ? this.store.bankAccounts.accountNumber
        : this.store.bank
        ? this.store.bank.accountNumber
        : null,
    
      accountDigit: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
        ? this.store.bankAccounts[0].accountDigit
        : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
        ? this.store.bankAccounts.accountDigit
        : this.store.bank
        ? this.store.bank.accountDigit
        : null,
    
        accountType: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
        ? this.store.bankAccounts[0].accountType
        : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
        ? this.store.bankAccounts.accountType
        : this.store.bank
        ? this.store.bank.accountType
        : null,
    
      isInternational: this.store.isInternational,
      currency: this.store.currency,
      zoopRevenue: this.store.zoopRevenue,
    });
    
  
    if (this.store.pointsConversionFactorIn) {
      this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required, Validators.min(1)]);
      this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
    }
  
    if (this.store.pointsConversionFactorOut) {
      this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required, Validators.min(1)]);
      this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity();
    }
  }
  
  displaySegment (seg) {
    return seg ? `${seg}` : ''
  }

  convertPercentToNumber (value) {
    if (value) {
      return numeral(Number(value)).divide(100).value()
    } else {
      return (value = 0)
    }
  }

  loadCampaigns () {
    this.campaignService
      .listCampaigns()
      .subscribe(
        response => {
          this.campaigns = response.results
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
  }

  verifyFormValidation () {
    if (
      !this.formGroup.valid ||
      this.subStoreNameInvalid ||
      this.macroGroupIdInvalid ||
      this.subAndMacroInvalid
    ) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    return true
  }

  async openDialogCampaignType () {
    if (this.verifyFormValidation()) {
      if (this.isCurrencyInputValueValid()) {
        if (this.storeID) {
          const macroGroupsIds = []
          this.formGroup.get('subStores')
            .value.map(macro => macroGroupsIds.push(macro.macroGroupId))
          this.campaignService.verifyStores(
            { macroGroupsIds: macroGroupsIds, storesId: this.storeID },
          )
            .toPromise().then(
              async response => {
                const parsedBindedMacroList = []
                response.results.forEach(macro => {
                  if (parsedBindedMacroList
                    .includes(macro.participatingCampaignWithMacroGroup.macroGroups.id)) {
                    macro.participatingCampaignWithMacroGroup.macroGroups = {}
                  } else {
                    parsedBindedMacroList
                      .push(macro.participatingCampaignWithMacroGroup.macroGroups.id)
                  }

                  if (parsedBindedMacroList
                    .includes(macro.participatingCampaignWithMacroGroup.campaigns.id)) {
                    macro.participatingCampaignWithMacroGroup.campaigns = {}
                  } else {
                    parsedBindedMacroList
                      .push(macro.participatingCampaignWithMacroGroup.campaigns.id)
                  }
                })
                if (response.results.length > 0) {
                  const dialogRef = this.dialog.open(ModalPartnerEditionMacroBindingInformation, {
                    width: '500px',
                    data: response,
                  })

                  dialogRef.afterClosed().subscribe(result => {
                    if (result !== 'cancel') {
                      this.submit().catch(err => console.log(err))
                    }
                  })
                } else {
                  this.submit().catch(err => console.log(err))
                }
              },
              error => console.log(error),
            )
        } else {
          const defaultCampaignsList = []
          const extraCampaignsList = []

          this.campaigns.map(campaign => {
            if (campaign.isDefault) {
              defaultCampaignsList.push(campaign)
            } else {
              extraCampaignsList.push(campaign)
            }
          })
          const dialogRef = this.dialog.open(ModalChooseCampaignTypeComponent, {
            width: '500px',
            data: {
              campaigns: { default: defaultCampaignsList, extra: extraCampaignsList },
            },
          })

          dialogRef.afterClosed().subscribe(result => {
            if (result !== 'cancel') {
              this.campaignId = result.campaignId
              this.defaultCampaignId = result.defaultCampaignId
              this.submit().catch(err => console.log(err))
            }
          })
        }
      }
    }
  }

  isCurrencyInputValueValid () {
    const parsedPointsConvertionFactorIn =
      Math.round(this.formGroup.value.pointsConversionFactorIn * 100)
    const parsedPointsConvertionFactorOut =
      Math.round(this.formGroup.value.pointsConversionFactorOut * 100)
    if ((parsedPointsConvertionFactorIn > 0 && parsedPointsConvertionFactorIn < 100) ||
      (parsedPointsConvertionFactorOut > 0 && parsedPointsConvertionFactorOut < 100)) {
      this.snackBar.open('Escolha um valor maior ou igual a R$1,00')
      return false
    }
    return true
  }
  changeIsInternationalFlag(e) {
    this.isInternational = e.value
  }

  async submit () {
    if (typeof this.formGroup.value.taxAdministrative === 'string') {
      const taxAdm = this.formGroup.value.taxAdministrative.replace(',', '.')
      this.formGroup.value.taxAdministrative = Number(taxAdm)
    }

    if (typeof this.formGroup.value.taxRewardsTransaction === 'string') {
      const taxAdm = this.formGroup.value.taxRewardsTransaction.replace(',', '.')
      this.formGroup.value.taxRewardsTransaction = Number(taxAdm)
    }

    if (typeof this.formGroup.value.taxCashback === 'string') {
      const taxAdm = this.formGroup.value.taxCashback.replace(',', '.')
      this.formGroup.value.taxCashback = Number(taxAdm)
    }

    if (typeof this.formGroup.value.internationalPartnerJcoinTransactionTax === 'string') {
      const taxAdm = this.formGroup.value.internationalPartnerJcoinTransactionTax.replace(',', '.')
      this.formGroup.value.internationalPartnerJcoinTransactionTax = Number(taxAdm)
    }

    const parsedMinimunTransactionValue =
      Math.round(this.formGroup.value.minimumTransactionValue * 100)
    const parsedMinimunInstallmentValue =
      Math.round(this.formGroup.value.minimumInstallmentValue * 100)
    const parsedPointsConvertionFactorIn =
      Math.round(this.formGroup.value.pointsConversionFactorIn * 100)
    const parsedPointsConvertionFactorOut =
      Math.round(this.formGroup.value.pointsConversionFactorOut * 100)

    if ((parsedPointsConvertionFactorIn > 0 && parsedPointsConvertionFactorIn < 100) ||
      (parsedPointsConvertionFactorOut > 0 && parsedPointsConvertionFactorOut < 100)) {
      this.snackBar.open('Escolha um valor maior ou igual a R$1,00')
      return false
    }

    const data = {
      nomeFantasia: this.formGroup.value.nomeFantasia,
      razaoSocial: this.formGroup.value.razaoSocial,
      emailAddress: this.formGroup.value.emailAddress,
      phoneNumber: this.formGroup.value.phoneNumber ? this.formGroup.value.phoneNumber : null,
      openingDate: this.formGroup.value.openingDate,
      liquidationDays: this.formGroup.value.liquidationDays,
      mccCategory: this.formGroup.value.mccCategory,
      mccDescription: this.formGroup.value.mccDescription,
      mccCode: this.formGroup.value.mccCode,
      taxCashback: this.convertPercentToNumber(this.formGroup.value.taxCashback),
      internationalPartnerJcoinTransactionTax: this.convertPercentToNumber(this.formGroup.value.internationalPartnerJcoinTransactionTax),
      taxAdministrative: this.convertPercentToNumber(this.formGroup.value.taxAdministrative),
      taxRewardsTransaction: this.convertPercentToNumber(
        this.formGroup.value.taxRewardsTransaction,
      ),
      taxCreditCardTransaction: this.convertPercentToNumber(
        this.formGroup.value.taxCreditCardTransaction,
      ),
      pointsExpiration: this.formGroup.value.pointsExpiration,
      street: this.formGroup.value.street,
      number: this.formGroup.value.number,
      neighborhood: this.formGroup.value.neighborhood,
      city: this.formGroup.value.city,
      state: this.formGroup.value.state,
      zipCode: this.formGroup.value.zipCode,
      complement: this.formGroup.value.complement,
      isMembershipActive: this.formGroup.value.isMembershipActive,
      isPayActive: this.formGroup.value.isPayActive,
      manualPaymentEnabled: this.formGroup.value.manualPaymentEnabled,
      futurePaymentEnabled: this.formGroup.value.futurePaymentEnabled,
      isConciergePaymentStore: this.formGroup.value.isConciergePaymentStore,
      maximumInstallmentsPerTransaction: this.formGroup.value.maximumInstallmentsPerTransaction
        ? this.formGroup.value.maximumInstallmentsPerTransaction
        : 0,
      // "maximumPointsPerTransaction": this.formGroup.value.maximumPointsPerTransaction,
      minimumTransactionValue: parsedMinimunTransactionValue,
      minimumInstallmentValue: parsedMinimunInstallmentValue,
      pointsConversionFactorIn: parsedPointsConvertionFactorIn,
      pointsConversionFactorOut: parsedPointsConvertionFactorOut,
      isCardPayment: this.isCardPayment,
      isJcoinsPayment: this.isJcoinsPayment,
      isJcoinsPriorityPayment: this.isJcoinsPriorityPayment,
      macro: this.formGroup.value.subStores,
      isSubStore: this.isSubStore,
      bank: {
        bankName: this.formGroup.value.bankName,
        agency: this.formGroup.value.agency,
        account: this.formGroup.value.accountNumber,
        digit: this.formGroup.value.accountDigit,
        accountType: this.formGroup.value.accountType,
      },
      defaultCampaignId: this.defaultCampaignId,
      campaignId: this.campaignId,
      document: '',
      isInternational: this.formGroup.value.isInternational,
      currency: this.formGroup.value.isInternational ? this.formGroup.value.currency : null,
      zoopRevenue: this.formGroup.value.zoopRevenue
    }

    if (this.subStoreNone.length) {
      data.macro = Array.from(new Set(data.macro.concat(this.subStoreNone)));
    }
    
    const unique = Array.from(new Set(data.macro));
    data.macro = unique

    if (this.isSubStore) {
      const subStores = this.formGroup.get('subStores') as FormArray
      if (subStores.controls.length) {
        this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName
        this.macroGroupIdInvalid = !subStores.controls[0].value.macroGroupId
        for (const a of subStores.controls) {
          if (a.value.subStoreName && !a.value.macroGroupId) {
            this.messageMacroGroupError = true
          } else {
            this.messageMacroGroupError = false
          }
          if (this.isSubStore && !a.value.subStoreName && a.value.macroGroupId) {
            this.messageSubError = true
          } else {
            this.messageSubError = false
          }
        }
      } else {
        this.subAndMacroInvalid = true
      }
    }
    if (this.cnpjSelected > 0) {
      data.document = this.formGroup.value.document
    }

    if (this.messageMacroGroupError) {
      this.snackBar.open('Escolha um Macro Grupo para Sub-empresa')
      return false
    }

    if (this.messageSubError) {
      this.snackBar.open('Escolha uma Sub-empresa para o Macro Grupo')
      return false
    }
    const subStores = this.formGroup.get('subStores') as FormArray
    this.macroGroupIdInvalid = !subStores.value.find(() => true).macroGroupId
    if (
      !this.formGroup.value.futurePaymentEnabled &&
      !this.formGroup.value.manualPaymentEnabled &&
      !this.formGroup.value.isMembershipActive
    ) {
      this.snackBar.open('Escolher opção de configuração')
      return false
    }

    this.verifyFormValidation()

    if (!this.storeID) {
      this.storeService.addInternationalStore(data).subscribe(
        async _ => {
          this.snackBar.open('Parceiro cadastrado com sucesso.')
          this.router.navigate(['dashboard', 'stores'])
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.storeService.updateInternationalStore(data, this.storeID).subscribe(
        async response => {
          this.checkIfBankAccountChanged(response)
        },
        error => {
          console.log(error.error.message)
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  async checkIfBankAccountChanged(response){

    if(response.isBankAccountChanged){

      const hasScheduledTransfers = await this.liquidationTrasnferService.checkIfFutureSchedulesExistsForStore(this.storeID).toPromise()

      if(hasScheduledTransfers) {
        const dialogRef = this.dialog.open(ModalStoreBankAccountChangedComponent, {
          width: '500px',
          data: response,
        })

        dialogRef.afterClosed().subscribe(async result => {
          if (result === 'confirm') {
            await this.liquidationTrasnferService.updateScheduledLiquidationTransfersBankAccount(this.storeID).toPromise()
          }
          this.onUpdateSuccess()
        })
        return
      }
    }

    this.onUpdateSuccess()
  }

  onUpdateSuccess(){
    this.snackBar.open('Parceiro atualizado com sucesso.')
    this.router.navigate(['dashboard', 'stores'])
  }

  onChange (event) {
    this.formGroup.patchValue({
      mccCategory: event.category,
      mccDescription: event.description,
      mccCode: event.code,
    })
  }

  updatePhoneInput() {
    this.cdr.detectChanges();
  }
}
