import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import numeral from 'numeral';
import { ModalFormStatementByCustomerComponent } from '../../../../components/modal-form-statementByCustomer/modal-form-statementByCustomer.component';
import { ModalFormChargeBackComponent } from 'src/app/components/modal-form-charge-back/modal-form-charge-back.component';
import { JcoinsMovementState } from 'src/app/models/jcoinsMovementState';
var ClientMovementJcoinsComponent = /** @class */ (function () {
    function ClientMovementJcoinsComponent(route, authService, snackBar, location, storeService, dialog) {
        this.route = route;
        this.authService = authService;
        this.snackBar = snackBar;
        this.location = location;
        this.storeService = storeService;
        this.dialog = dialog;
        this.headersTable = [
            'transactionId',
            'transactionDate',
            'partnerName',
            'transactionDescription',
            'transactionValue',
            'state',
            'expiration',
            'flow',
            'jcoinQtde',
            'jcoinValue',
            'accumulationType',
            'chargeBack',
        ];
        this.params = { formGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ClientMovementJcoinsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userRole = localStorage.getItem('userRole');
        this.architect = this.route.snapshot.paramMap.get('architect');
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.customerId = this.route.snapshot.paramMap.get('customerID');
        this.init().catch(console.log);
    };
    ClientMovementJcoinsComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.fieldsForm = [
                            {
                                field: 'input',
                                type: 'date',
                                label: 'Data Início',
                                formControlName: 'baginDateMovementJcoins',
                            },
                            {
                                field: 'input',
                                type: 'date',
                                label: 'Data Final',
                                formControlName: 'endDateMovementJcoins',
                            },
                        ];
                        return [4 /*yield*/, this.storeService
                                .listUsersCustomers("?filters={\"filters\":[{\"fields\":[\"customers.id\"], \"op\": \"=\", \"value\":\"" + this.customerId + "\"}]}")
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var customer;
                                return tslib_1.__generator(this, function (_a) {
                                    customer = response.items.find(function () { return true; });
                                    this.cpf = customer.cpf;
                                    this.fullName = customer.firstName + " " + customer.lastName;
                                    this.email = customer.emailAddress;
                                    this.jcoinBalance = customer.jcoinBalance;
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.authService
                                .listMovementJcoins(this.cpf, "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + "}")
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.pageTotal = response.total;
                                    this.dataSource = new MatTableDataSource(response.results);
                                    console.log(this.dataSource);
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientMovementJcoinsComponent.prototype.goBack = function () {
        this.location.back();
    };
    ClientMovementJcoinsComponent.prototype.openDialogChargeBack = function (element) {
        var _this = this;
        this.dialog
            .open(ModalFormChargeBackComponent, {
            width: '500px',
            data: {
                partnerName: element.partnerName,
                transactionDate: element.creditDatePreRegistration ? element.creditDatePreRegistration : element.transactionDate,
                jcoinValue: element.jcoinValue,
                walletId: element.walletExecutionId,
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    this.snackBar.open('Estorno realizado com sucesso!');
                    this.init().catch(console.log);
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    ClientMovementJcoinsComponent.prototype.convertState = function (jcoins) {
        return JcoinsMovementState[jcoins.state];
    };
    ClientMovementJcoinsComponent.prototype.convertFlow = function (flow) {
        if (flow === 'IN')
            return 'ENTRADA';
        return 'SAÍDA';
    };
    ClientMovementJcoinsComponent.prototype.convertCentsToReal = function (jcoin) {
        return numeral(Number(jcoin.transactionValue)).divide(100).value();
    };
    ClientMovementJcoinsComponent.prototype.report = function (cpf, fullName) {
        this.dialog.open(ModalFormStatementByCustomerComponent, {
            width: '550px',
            data: {
                cpf: cpf,
                fullName: fullName,
            },
        });
    };
    ClientMovementJcoinsComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return ClientMovementJcoinsComponent;
}());
export { ClientMovementJcoinsComponent };
