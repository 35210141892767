import { Component, OnInit } from '@angular/core'
import { MatDialogRef, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { ReportService } from '../../services/report.service'
import { ErrorsService } from 'src/app/core/services/errors.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-jcoins-initial-stock',
  templateUrl: './modal-form-jcoins-initial-stock.component.html',
  styleUrls: ['./modal-form-jcoins-initial-stock.component.scss'],
})
export class ModalFormJcoinsInitialStockComponent implements OnInit {
  public formGroup: FormGroup
  public selectedCustomers: any[] = []
  public customerControl = new FormControl()

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    public dialogRef: MatDialogRef<ModalFormJcoinsInitialStockComponent>,
  ) { }

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      month: [null, [Validators.required]],
      year: [(new Date()).getFullYear(), [Validators.required]],
    })
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    this.reportService
      .createReport(`jcoins-initial-stock?month=${this.formGroup.value.month}&year=${this.formGroup.value.year}`)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return 'Relatório de Estoque Inicial de Jcoins.' + response.fileUrl.split('.').splice(5, 5).join('.')
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
